import React from "react";
import { Link } from "gatsby";
import { LandingButton as LandingButtonProps } from "../../models";

const LandingButton: React.FC<LandingButtonProps> = ({
  id,
  className,
  text,
  clickType,
  sectionAnchor,
  pageRedirect,
  external,
}) => <Link
  id={id}
  className={`${className} inline-flex justify-center items-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-swatch_2 hover:bg-swatch_5`}
  to={
    clickType === "Anchor" ? `#${sectionAnchor.SectionAnchor.anchorText.anchorText}` :
      clickType === "Redirect" ? pageRedirect?.uri :
        external?.url}
  target={external?.target}
>
    {text}
  </Link>

export default LandingButton;
