import React from "react";
import { Field } from "formik";
import Iubenda from "react-iubenda-policy";

import Lead from "../../models/lead";
import { BasicForm, BasicFormProps } from "./basic-form";

type FormDemoProps = BasicFormProps & {
  onSubmit?: (values: Lead) => void;
};

const FormDemo: React.FC<FormDemoProps> = (
  props: FormDemoProps
): JSX.Element => {
  return (
    <BasicForm
      initialValues={{
        firstname: "",
        lastname: "",
        company: "",
        phonenumber: "",
        email: "",
        newsletter: "",
      }}
      {...props}
      mailchimp={props.mailchimp}
      redirect={props.redirect}
    >
      <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
        <div>
          <label
            htmlFor='first_name'
            className='block text-sm font-medium text-gray-700'
          >
            Il tuo nome *
          </label>
          <div className='mt-1'>
            <Field
              type='text'
              name='firstname'
              id='firstname'
              autoComplete='given-name'
              className='py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
              required
            />
          </div>
        </div>
        <div>
          <label
            htmlFor='last_name'
            className='block text-sm font-medium text-gray-700'
          >
            Il tuo cognome *
          </label>
          <div className='mt-1'>
            <Field
              type='text'
              name='lastname'
              id='lastname'
              autoComplete='family-name'
              className='py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
              required
            />
          </div>
        </div>
        <div className='sm:col-span-2'>
          <label
            htmlFor='company'
            className='block text-sm font-medium text-gray-700'
          >
            La tua azienda *
          </label>
          <div className='mt-1'>
            <Field
              type='text'
              name='company'
              id='company'
              autoComplete='organization'
              className='py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
              required
            />
          </div>
        </div>
        <div className='sm:col-span-2'>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            La tua migliore email *
          </label>
          <div className='mt-1'>
            <Field
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              className='py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
              required
            />
          </div>
        </div>
        <div className='sm:col-span-2'>
          <label
            htmlFor='phone_number'
            className='block text-sm font-medium text-gray-700'
          >
            Il tuo numero
          </label>
          <div className='mt-1 relative rounded-md shadow-sm'>
            <Field
              type='text'
              name='phonenumber'
              id='phonenumber'
              autoComplete='tel'
              className='py-3 px-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
              placeholder='+39 333 112 2334'
            />
          </div>
        </div>
        <div className='sm:col-span-2 space-y-2'>
          <div className='flex items-start'>
            <div className='flex-shrink-0'>
              <input
                type='checkbox'
                id='newsletter'
                name='newsletter'
                className='form-checkbox'
                role='checkbox'
              ></input>

              <span className='sr-only'>Accetto la Privacy Policy</span>
            </div>
            <div className='ml-3'>
              <p className='text-base text-gray-500 inline-flex space-x-1'>
                Ricevi la nostra newsletter
              </p>
            </div>
          </div>
          <div className='flex items-start'>
            <div className='flex-shrink-0'>
              <input
                type='checkbox'
                id='privacy'
                name='privacy'
                className='form-checkbox'
                role='checkbox'
                value='YES'
                required
              ></input>

              <span className='sr-only'>Accetto la Privacy Policy</span>
            </div>
            <div className='ml-3'>
              <p className='text-base text-gray-500 inline-flex items-center space-x-1'>
                <span>Accetto la</span>
                <Iubenda id='74634079' type='privacy' styling='white'>
                  Privacy Policy
                </Iubenda>
              </p>
            </div>
          </div>
        </div>
        <div className='sm:col-span-2'>
          <button
            type='submit'
            className='w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-swatch_2 hover:bg-swatch_5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            Richiedi una demo
          </button>
        </div>
      </div>
    </BasicForm>
  );
};

export default FormDemo;
