import React from "react";
import {
  CurrencyEuroIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  CheckIcon,
  CloudDownloadIcon,
  ChartPieIcon,
  AdjustmentsIcon,
  ChartBarIcon,
  DocumentSearchIcon,
  MailIcon,
  PhotographIcon,
  TagIcon,
} from "@heroicons/react/outline";

import LayoutV2 from "../components/layout-v2";
import FeaturesAlternatingToLeft from "../components/ui-v2/features-alternating-to-left";
import GridWithOffsetIcons from "../components/ui-v2/grid-with-offset-icons";
import HeroAngledImage from "../components/ui-v2/hero-angled-image";
import FeaturesAlternatingToRight from "../components/ui-v2/features-alternating-to-right";
import FeaturesThreeColumns from "../components/ui-v2/features-three-columns";
import Feature from "../components/ui-v2/feature";
import TeamV2 from "../components/ui-v2/team-v2";
import TeamMember from "../components/ui-v2/team-member";
import BottomCTA from "../components/ui-v2/bottom-cta";
import Testimonials from "../components/testimonials";
import TimeSaveCalculator from "../components/time-saver-calculator";
import Lead from "../models/lead";
import BlogBackendService from "../services/backend.service";
import { TrackingSpecs, TrackingSpecsResponse } from "../models/tracking-specs";
import { useState } from "react";
import TimeSavedResult from "../components/time-saved-result";
import CTA from "../components/shared/cta";
import Navbar from "../components/navbar";

const features = [
  {
    name: "Disponibilità dei prodotti",
    description:
      "Scopri lo stato di disponibilità dei prodotti concorrenti e pianifica attività di marketing precise.",
    icon: ShoppingCartIcon,
  },
  {
    name: "Tasso di penetrazione e di adozione",
    description:
      "Individua nuove opportunità di vendita e di espansione aziendale.",
    icon: ChartPieIcon,
  },
  {
    name: "Strategia di pricing",
    description:
      "Come posizionano e a quale livello di prezzo vendono i loro prodotti per ogni Paese?",
    icon: CurrencyEuroIcon,
  },
];

const Landing: React.FC = (props): JSX.Element => {
  const [trackingSpecs, setTrackingSpecs] = useState<TrackingSpecs>();
  const [timeSaved, setTimeSaved] = useState<number>();

  return (
    <LayoutV2
      seo={{
        title: "Pricyo per i produttori",
        metaDesc: `Monitora i tuoi rivenditori e assicurati che rispettino i prezzi, 
        non svalutando l'immagine del brand. Non dovrai più farlo manualmente.`,
        description: `Monitora i tuoi rivenditori e assicurati che rispettino i prezzi, 
        non svalutando l'immagine del brand. Non dovrai più farlo manualmente.`,
        canonical: '/brand/'
      }}
    >
      <HeroAngledImage
        title={
          <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
            <span className='block text-swatch_6 xl:inline'>Proteggi </span>
            <span className='block xl:inline'>il tuo brand e </span>
            <span className='block text-swatch_6 xl:inline'>risparmia </span>
            <span className='block xl:inline'>tempo</span>
          </h1>
        }
        description={`
        Monitora i tuoi rivenditori e assicurati che rispettino i prezzi, 
        non svalutando l'immagine del brand. Non dovrai più farlo manualmente.`}
      >
        <CTA
          id='CTA-landing_brand-headline-above_the_fold'
          text='Richiedi una demo'
          to='#demo'
        />
      </HeroAngledImage>

      <GridWithOffsetIcons
        title={`Tutto quello che ti serve è a portata di un click`}
        description={`Dedica il tuo tempo alle attività veramente strategiche, lascia che sia Pricyo a svolgere il lavoro lungo e noioso di raccolta dei dati.`}
        features={[
          {
            name: "Monitoraggio prezzi",
            icon: CurrencyEuroIcon,
            description:
              "Monitoriamo i prezzi per assicurarti una visione sempre completa del mercato.",
          },
          {
            name: "MAP e MSRP",
            icon: TagIcon,
            description:
              "Scopri quali shop utilizzano le tue etichette come prodotti civetta.",
          },
          {
            name: "Alert via mail",
            icon: MailIcon,
            description:
              "Ricevi nella tua email le principali variazioni dei prezzi e disponibilità.",
          },
          {
            name: "Screenshot",
            icon: PhotographIcon,
            description:
              "Mostra ai rivenditori che hanno violato il tuo MAP o MSRP",
          },
          {
            name: "Analizza le schede prodotto",
            icon: DocumentSearchIcon,
            description:
              "Assicurati che le informazioni siano corrette e non svalutino il tuo brand.",
          },
          {
            name: "Esporta i dati",
            icon: CloudDownloadIcon,
            description:
              "Esporta i dati di Pricyo nel comodo formato CSV con un click.",
          },
          {
            name: "Grafici dedicati",
            icon: ChartPieIcon,
            description:
              "Insight precisi e completi per avere tutte le informazioni necessarie in un batter d’occhio.",
          },
          {
            name: "Storico dei dati",
            icon: ChartBarIcon,
            description:
              "Analizza i trend e scopri come si è comportata la rete distributiva nel tempo.",
          },
          {
            name: "Soluzione flessibile",
            icon: AdjustmentsIcon,
            description:
              "Scegli il piano basato sulle tue necessità, modificalo quando vuoi.",
          },
        ]}
      />

      {!timeSaved ? (
        <TimeSaveCalculator
          title={"Scopri quanto tempo risparmieresti utilizzando Pricyo"}
          onSubmit={async (data: TrackingSpecs) => {
            const { timeSaved }: TrackingSpecsResponse =
              await BlogBackendService.calculateTimeSaved(data);

            window.dataLayer?.push({
              event: "CTA-landing_brand-form-risparmio_tempo",
            });
            setTrackingSpecs(data);
            setTimeSaved(timeSaved);
          }}
        ></TimeSaveCalculator>
      ) : (
        <TimeSavedResult
          trackingSpecs={trackingSpecs}
          timeSaved={timeSaved ? timeSaved : 0}
          ctaId='CTA-landing_brand-form-risparmio_tempo'
          ctaText='Inizia subito a risparmiare tempo'
          anchor='#demo'
          resetTrackingSpecs={() => {
            setTrackingSpecs(undefined);
            setTimeSaved(undefined);
          }}
        ></TimeSavedResult>
      )}

      <Testimonials
        title='Alcuni brand che hanno scelto Pricyo'
        testimonials={[
          "https://cms.pricyo.com/wp-content/uploads/2021/07/allegrini-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/abbazia-novacella-logo.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/cantina-terlano-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/baglio-pianetto-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/12/feudi-san-gregorio.png"
        ]}
      ></Testimonials>

      <FeaturesAlternatingToLeft
        title='Monitoraggio di prezzi e sconti'
        description={
          <p>
            Monitoriamo automaticamente i prezzi di vendita della tua rete
            distributiva domestica ed estera, senza vincoli di paese, valuta o
            Ecommerce. <br />
            <br /> Imposta il software e poi non dovrai più preoccuparti di
            nulla, ci penserà Pricyo ad aggiornare costantemente i prodotti
            monitorati considerando anche le uscite delle nuove annate.
          </p>
        }
        icon={
          <CurrencyEuroIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/monitoraggio-prezzi-sconti.png'
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7 space-y-2'>
            <li className='inline-flex items-start space-x-2'>
              <span>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </span>

              <span>
                <span className='text-gray-600 font-semibold'>
                  Storico dei dati:
                </span>
                Analizza l’andamento dei prezzi per ogni rivenditore o singolo
                prodotto.
              </span>
            </li>
            <li className='flex justify-between items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'>
                  Feedback visivo delle violazioni del MAP e MSRP:
                </span>
                Ottieni a colpo d’occhio un’indicazione su chi sono gli shop
                problematici.
              </span>
            </li>
            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'>Alert </span> via
                email per le principali variazioni.
              </span>
            </li>
            <li className='flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'>
                  Screenshot della pagina web
                </span>
                in caso di violazione del MAP o MSRP.
              </span>
            </li>
          </ul>
          <CTA
            id='CTA-landing_brand-price_tracking'
            text='Richiedi una demo'
            to='#demo'
          />
        </div>
      </FeaturesAlternatingToLeft>

      <FeaturesAlternatingToRight
        title='Analizza come i rivenditori presentano il tuo brand'
        description={`
          Non c’è pubblicità peggiore di un’immagine non rappresentativa del brand o nomi inesatti. 
          Analizzando centinaia di Ecommerce nel settore vinicolo ci siamo accorti che questa circostanza è più 
          frequente di quanto ci si possa aspettare.
        `}
        icon={
          <ShieldCheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/presentazione-brand.png '
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7'>
            <li className='inline-flex items-center space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'></span>
                Anteprima delle foto utilizzate.
              </span>
            </li>

            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'></span>
                Ingrandisci l’immagine per verificare che l’etichetta sia
                aggiornata e corretta.
              </span>
            </li>
          </ul>
          <CTA
            id='CTA-landing_brand-schede_prodotto'
            text='Scopri di più'
            to='#demo'
          />
        </div>
      </FeaturesAlternatingToRight>

      <FeaturesAlternatingToLeft
        title='Monitora la disponibilità e la share on shelf'
        description={`
          Ottimizza i cicli di approvvigionamento dei tuoi distributori strategici. Scopri quali sono i prodotti esauriti e quelli non presenti nel catalogo dello shop.

          Ricevi un alert via email ogni volta che::`}
        icon={
          <ShoppingCartIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/pricyo-screen-disponibilità-2.png'
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7'>
            <li className='inline-flex items-start space-x-2'>
              <span>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </span>

              <span>Viene rilevata una variazione nella disponibilità</span>
            </li>
            <li className='flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                Un Ecommerce inizia a commercializzare il prodotto monitorato.
              </span>
            </li>
          </ul>
          <CTA
            id='CTA-landing_brand-disponibilita'
            text='Richiedi una demo'
            to='#demo'
          />
        </div>
      </FeaturesAlternatingToLeft>

      <FeaturesAlternatingToRight
        title='Esporta i dati'
        description={`
          Integra i dati Pricyo con altri tuoi strumenti di analisi. 
          Scarica il report di tuo interesse nel comodo formato CSV.
        `}
        icon={
          <CloudDownloadIcon
            className='h-6 w-6 text-white'
            aria-hidden='true'
          />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/pricyo-export.png'
      >
        <CTA
          id='CTA-landing_brand-export_dati'
          text='Richiedi una demo'
          to='#demo'
        />
      </FeaturesAlternatingToRight>

      <FeaturesThreeColumns
        title='Analisi della concorrenza'
        description={`Confronta le tue metriche con quelle della concorrenza per trovare
          eventuali punti deboli e nuove opportunità di business.`}
      >
        {features.map((feature) => (
          <Feature {...feature} />
        ))}
        <CTA
          id='CTA-landing_brand-analisi_concorrenti'
          text='Scopri di più'
          to='#demo'
        />
      </FeaturesThreeColumns>

      <TeamV2
        id='tema'
        title='Il Team di Pricyo'
        description={`Siamo Maker, realizziamo soluzioni ad alto contenuto tecnologico
              al servizio delle aziende. Pricyo è nato per aiutare i produttori
              e i rivenditori a monitorare il panorama distributivo e
              competitivo online e prendere migliori decisioni di business.`}
      >
        <TeamMember
          name='Edoardo Maria Erriu'
          role='CEO'
          description='Gestisce la strategia web e la pubblicità per Ecommerce e Brand sul mercato domestico ed estero.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/edoardo-maria-erriu-web-marketing-1-e1605307748764.jpg'
        ></TeamMember>
        <TeamMember
          name='Davide Sicignani'
          role='CIO'
          description='Ha realizzato il primo software di monitoraggio per un investitore di opere d’arte, monitorando i principali siti d’aste europei.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/davide-sicignani-web-developer.jpg'
        ></TeamMember>
        <TeamMember
          name='Ugo Buonadonna'
          role='CTO'
          description=' Lavora su interfacce e algoritmi con enormi moli di dati e
                    metodi per estrapolare efficacemente informazioni con scopi di business.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/ugo.jpeg'
        ></TeamMember>
      </TeamV2>

      <BottomCTA
        id='demo'
        title='Pricyo è la suite di cui hai bisogno per tutelare il tuo brand'
        description={
          <span>
            Un unico strumento per analizzare il posizionamento del tuo brand,
            ottenere insight preziosi e sempre aggiornati sulla tua rete
            distributiva domestica ed estera. <br />
            Risparmiando tempo e con meno stress.
          </span>
        }
        features={[
          "Monitoraggio dei prezzi",
          "Violazioni MAP e MSRP",
          "Alert in tempo reale",
          "Screenshot",
          "Monitoraggio della disponibilità",
          "Analisi delle schede prodotto",
          "Storico dei dati",
          "Export dei dati",
        ]}
        leadsTags={[{ name: "Richiedi demo" }, { name: "Demo produttore" }]}
        gtmEvent='Form lead brand'
      ></BottomCTA>
    </LayoutV2>
  );
};

export default Landing;
