/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Feature from "../../models/feature";

type GridWithOffsetIconsProps = {
  preTitle: string;
  title: string;
  description: string;
  features: Feature[];
};

const GridWithOffsetIcons: React.FC<GridWithOffsetIconsProps> = ({
  preTitle,
  title,
  description,
  features,
}): JSX.Element => (
  <div className="relative bg-white py-16 sm:py-24 lg:py-32">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="text-base font-semibold tracking-wider text-orange-600 uppercase">
        {preTitle}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-swatch_3 tracking-tight sm:text-4xl">
        {title}
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-swatch_7">
        {description}
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    {feature.icon ? <span className="inline-flex items-center justify-center p-3 bg-swatch_6 rounded-md shadow-lg">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span> : <></>}
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-swatch_3 tracking-tight">
                    {feature.name}
                  </h3>
                  <p className="mt-5 text-base text-swatch_7">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default GridWithOffsetIcons;
