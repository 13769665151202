/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Popover } from "@headlessui/react";
import { ReactElement } from "react";
import Navbar from "../navbar";

type HeroAngledImageProps = {
  title: ReactElement;
  description: string | ReactElement;
  children?: React.ReactNode;
};

const HeroAngledImage: React.FC<HeroAngledImageProps> = (
  props: HeroAngledImageProps
): JSX.Element => (
  <div className="relative bg-white overflow-hidden">
    <div className="max-w-7xl mx-auto">
      <div className="pt-36 relative z-10 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <main className="pt-10 mx-auto max-w-7xl px-4 sm:px-6">
          <div className="sm:text-center lg:text-left">
            {props.title}
            <p className="mt-3 text-base text-swatch_7 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              {props.description}
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">{props.children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        src={`https://cms.pricyo.com/wp-content/uploads/2021/04/hero-image.jpg`}
        alt="pricyo-wine"
      />
    </div>
  </div>
);

export default HeroAngledImage;
