import React from 'react';

type TeamV2Props = {
  id?: string;
  title: string;
  description: string;
  children?: any;
};

const TeamV2: React.FC<TeamV2Props> = (props: TeamV2Props) => (
  <div id={props.id} className='bg-white'>
    <div className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24'>
      <div className='space-y-12'>
        <div className='space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none'>
          <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
            {props.title}
          </h2>
          <p className='text-xl text-swatch_7'>{props.description}</p>
        </div>
        <ul className='space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8'>
          {props.children}
        </ul>
      </div>
    </div>
  </div>
);

export default TeamV2;
