import React from "react";

type FeatureProps = {
  name: string;
  description: string;
  icon: any;
};

const Feature: React.FC<FeatureProps> = (props: FeatureProps): JSX.Element => (
  <div key={props.name}>
    <dt>
      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-swatch_6 text-white">
        {<props.icon className="h-6 w-6" />}
      </div>
      <p className="mt-5 text-lg leading-6 font-medium text-swatch_3">
        {props.name}
      </p>
    </dt>
    <dd className="mt-2 text-base text-swatch_7">{props.description}</dd>
  </div>
);

export default Feature;
