import { AnchorLink, AnchorLinkProps } from "gatsby-plugin-anchor-links";
import React from "react";

type CTAProps = AnchorLinkProps & {
  id: string;
  text: string;
};

const CTA: React.FC<CTAProps> = ({ id, to, text }): JSX.Element => (
  <AnchorLink
    to={to}
    title={text}
    gatsbyLinkProps={{ id }}
    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-swatch_2 hover:bg-swatch_5 md:py-4 md:text-lg md:px-10'
  >
    {text}
  </AnchorLink>
);

export default CTA;
