import Axios from 'axios';
import environment from '../config/environment';
import CreateCheckoutSessionBody from '../models/create-checkout-session-body';
import AddContactToListBody from '../models/add-contact-to-list-body';
import { TrackingSpecsResponse, TrackingSpecs } from '../models/tracking-specs';

export default class BlogBackendService {
  static addContactToList(data: AddContactToListBody) {}

  static createCheckoutSession(
    data: CreateCheckoutSessionBody
  ): Promise<{ id: string }> {
    return Axios.post(
      `${environment.API.host}${environment.API.createCheckoutSession}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => response.data);
  }

  static calculateTimeSaved(
    data: TrackingSpecs
  ): Promise<TrackingSpecsResponse> {
    return Axios.post(
      `${environment.API.host}${environment.API.calculateTimeSaved}`,
      data
    ).then(({ data }) => data);
  }
}
