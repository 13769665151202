import { Form, Formik } from "formik";
import React from "react";
import TrackingSpecs from "../models/tracking-specs";

type TimeSaveCalculatorProps = {
  title: string;
  description?: string;
  onSubmit: (specs: TrackingSpecs) => void;
};

const TimeSaveCalculator: React.FC<TimeSaveCalculatorProps> = ({
  title,
  description,
  onSubmit,
}): JSX.Element => {
  return (
    <div className='relative bg-white'>
      <div className='absolute left-0 right-0 h-1/2' aria-hidden='true'></div>
      <div className='relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8'>
        <div className='py-10 px-6 bg-gradient-to-l from-swatch_2 to-swatch_6 rounded-3xl sm:py-16 sm:px-12 lg:py-20 lg:px-20 lg:flex lg:items-center'>
          <div className='lg:w-0 lg:flex-1'>
            <h2 className='text-3xl font-extrabold tracking-tight text-white'>
              {title}
            </h2>
            <p className='mt-4 max-w-3xl text-lg text-white'>{description}</p>
          </div>
          <div className='mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1'>
            <Formik
              initialValues={{ products: 0, sites: 0, countries: 0 }}
              onSubmit={(values, actions) => {
                onSubmit(values as TrackingSpecs);
              }}
            >
              {({ handleBlur, handleChange }) => (
                <Form className='flex flex-col space-y-2'>
                  <label htmlFor='email-address' className='sr-only'></label>
                  <input
                    type='number'
                    id='products'
                    name='products'
                    className='w-full border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none rounded-md'
                    placeholder='Quanti prodotti vuoi monitorare?'
                    autoComplete='email'
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></input>
                  <input
                    type='number'
                    id='sites'
                    name='sites'
                    className='w-full border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none rounded-md'
                    placeholder='Quanti siti vuoi monitorare?'
                    autoComplete='email'
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></input>
                  <input
                    type='number'
                    id='countries'
                    name='countries'
                    className='w-full border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none rounded-md'
                    placeholder='Quante nazioni vuoi monitorare?'
                    autoComplete='email'
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></input>
                  <button
                    type='submit'
                    className='mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-xl font-bold rounded-md text-swatch_2 bg-white hover:text-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 sm:w-auto sm:flex-shrink-0'
                  >
                    Calcola
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSaveCalculator;
