import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RefreshIcon } from "@heroicons/react/outline";

import { TrackingSpecs } from "../models/tracking-specs";
import LandingButton from "./shared/landing-button";
import { LandingButton as ILandingButton } from "../models";

type TimeSavedResultProps = {
  trackingSpecs?: TrackingSpecs;
  timeSaved: number;
  ctaId?: string;
  ctaText?: string;
  anchor?: string;
  button?: any;
  resetTrackingSpecs: () => void;
};

const TimeSavedResult: React.FC<TimeSavedResultProps> = ({
  trackingSpecs,
  timeSaved,
  anchor,
  ctaId,
  ctaText,
  button,
  resetTrackingSpecs,
}): JSX.Element => {
  return (
    <div className='relative bg-white'>
      <div className='absolute left-0 right-0 h-1/2' aria-hidden='true'></div>
      <div className='relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8'>
        <div className='py-10 px-6 bg-gradient-to-l from-swatch_2 to-swatch_6 rounded-3xl sm:py-16 sm:px-12 lg:py-20 lg:px-20 lg:flex lg:items-center'>
          <div className='space-y-2 lg:w-0 lg:flex-1'>
            <div className='inline-flex items-center space-x-2'>
              <span className='text-white font-medium text-sm'>
                Hai scelto di monitorare {trackingSpecs?.products} prodotti su{" "}
                {trackingSpecs?.sites} siti in {trackingSpecs?.countries}{" "}
                nazioni
              </span>
              <a
                className='w-4 h-4 inline-block text-white cursor-pointer'
                onClick={resetTrackingSpecs}
              >
                <RefreshIcon className='w-4 h-4 text-white' />
              </a>
            </div>
            <h2 className='text-3xl font-extrabold tracking-tight text-white'>
              Con Pricyo{" "}
              <span className='font-bold px-1 bg-white text-swatch_2'>
                risparmieresti {timeSaved} ore ogni mese
              </span>{" "}
              monitorando i tuoi rivenditori una volta alla settimana.
            </h2>
            <p className='mt-4 max-w-3xl text-lg text-white'></p>
          </div>
          <div className='mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1'>
            {
              // Feature toggle per fare in modo che le landing brand ed ecommerce continuino a funzionare dato che usano un altra tipologia di bottone 
              ctaText ? <AnchorLink
                to={anchor}
                gatsbyLinkProps={{ id: ctaId }}
                className='mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-swatch_2 bg-white hover:text-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 sm:w-auto sm:flex-shrink-0'
              >
                {ctaText}
              </AnchorLink> : <LandingButton className="w-full bg-white text-white border-white border-2" {...button} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSavedResult;
