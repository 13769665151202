/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import Feature from './feature';

type Feature = {
  name: string;
  description: string;
  icon: JSX.Element;
};

type FeaturesThreeColumnsProps = {
  title: string;
  description: string;
  ctaText?: string;
  anchor?: string;
  children?: any;
};

const FeaturesThreeColumns: React.FC<FeaturesThreeColumnsProps> = (
  props: FeaturesThreeColumnsProps
): JSX.Element => (
  <div className='py-20 bg-white'>
    <div className='max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 space-y-6'>
      <h2 className='text-3xl font-extrabold tracking-tight text-swatch_3'>
        {props.title}
      </h2>
      <p className='mt-4 text-lg text-swatch_7'>{props.description}</p>
      <dl className='mt-5 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
        {props.children}
      </dl>
      {props.anchor ? (
        <a
          href={props.anchor}
          className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-swatch_2 hover:bg-swatch_5 md:py-4 md:text-lg md:px-10'
        >
          {props.ctaText || 'Richiedi una Demo'}
        </a>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default FeaturesThreeColumns;
