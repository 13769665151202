import React from "react";

type TestimonialsProps = {
    title: any;
    testimonials: string[]
}

const Testimonials: React.FC<TestimonialsProps> = ({ title, testimonials }) => {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-swatch_2">{title}</h2>
          <div className="flow-root mt-8 lg:mt-10">
            <div className="-mt-4 -ml-8 lg:-ml-4 grid grid-cols-3 gap-6">
              {testimonials.map(logo => 
                <div className="mt-4 ml-8 flex flex-wrap justify-center lg:ml-4">
                    <img
                    className="h-20"
                    src={logo}
                    alt="Workcation"
                    />
                </div>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default Testimonials