/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { ShieldCheckIcon, CheckIcon } from "@heroicons/react/outline";

type FeaturesAlternatingToLeftProps = {
  title: string;
  description: React.ReactNode;
  image?: string;
  icon: JSX.Element;
  isRightImage?: boolean;
  children?: any;
};

const FeaturesAlternatingToLeft: React.FC<FeaturesAlternatingToLeftProps> = (
  props: FeaturesAlternatingToLeftProps
): JSX.Element => (
  <div className='relative bg-white pt-16 pb-32 overflow-hidden'>
    <div className='relative'>
      <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
        <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
          <div>
            <div>
              <span className='h-12 w-12 rounded-md flex items-center justify-center bg-swatch_2'>
                {props.icon}
              </span>
            </div>
            <div className='mt-6 space-y-6'>
              <h2 className='text-3xl font-extrabold tracking-tight text-swatch_3'>
                {props.title}
              </h2>
              <p className='text-lg text-swatch_7'>{props.description}</p>
              <div className='mt-3'>{props.children}</div>
            </div>
          </div>
        </div>
        <div className='mt-12 sm:mt-16 lg:mt-0'>
          <div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
            <img
              className='w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
              src={props.image}
              alt='Inbox user interface'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeaturesAlternatingToLeft;
