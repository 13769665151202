/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import FormDemo from "../leads-components/form-demo";
import Lead from "../../models/lead";
import { ReactElement } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type BottomCTAProps = {
  id?: string;
  title: string;
  description: ReactElement;
  features: string[];
  label?: string;
  leadsTags?: { name: string }[];
  gtmEvent?: string;
};

const BottomCTA: React.FC<BottomCTAProps> = (
  props: BottomCTAProps
): JSX.Element => (
  <div className='bg-white'>
    <div className=''>
      {/* Pricing section with single price and feature list */}
      <div className='max-w-7xl mx-auto py-16 px-3 sm:py-24 sm:px-6 lg:px-8'>
        <div className='flex flex-col lg:space-y-6 lg:mt-12 lg:px-48'>
          <div className='text-center space-y-5'>
            <h2 className='text-3xl font-extrabold lg:tracking-tight sm:text-4xl'>
              {props.title}
            </h2>
            <p className='text-xl text-swatch_7'>{props.description}</p>
          </div>
          <div className='mt-6 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2'>
            <ul className=''>
              {props.features.slice(0, 4).map((feature, featureIdx) => (
                <li
                  key={feature}
                  className={classNames(
                    featureIdx === 0 ? "md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className='flex-shrink-0 h-6 w-6 text-green-500'
                    aria-hidden='true'
                  />
                  <span className='ml-3 text-base text-gray-500'>
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
            <ul className=''>
              {props.features.slice(4).map((feature, featureIdx) => (
                <li
                  key={feature}
                  className={classNames(
                    featureIdx === 0 ? "md:border-t-0 md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className='flex-shrink-0 h-6 w-6 text-green-500'
                    aria-hidden='true'
                  />
                  <span className='ml-3 text-base text-gray-500'>
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <span id={props.id}></span>
          <FormDemo
            mailchimp={{
              audienceid: [
                {
                  id: "8",
                  slug: "8",
                  name: "",
                },
              ],
              tags: props.leadsTags || [
                {
                  name: "Richiesta demo",
                },
              ],
            }}
            redirect={{ url: "/thank-you" }}
            gtmEvent={props.gtmEvent}
          ></FormDemo>
        </div>
      </div>
    </div>
  </div>
);

export default BottomCTA;
